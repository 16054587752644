import React, { useEffect } from 'react'
import PageHeader from './components/globals/pageHeader'
import AroundTheWorld from './components/about/aroundTheWorld'
import TypesOfContainers from './components/home/typesOfContainers'
import Testimonials from './components/home/testimonials'

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col gap-5'>
                <PageHeader page="About Us" />
                <AroundTheWorld />
                <TypesOfContainers />
            </div>
            <Testimonials />
        </div>
    )
}

export default AboutUs