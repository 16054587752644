import React, { useEffect } from 'react'
import PageHeader from './components/globals/pageHeader'
import ContactForm from './components/contact/contactForm'

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='flex flex-col gap-5'>
            <PageHeader page="Contact Us" />
            <div className="my-10 md:my-20">
                <ContactForm />
            </div>
        </div>
    )
}

export default ContactUs