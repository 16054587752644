import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaPhoneAlt } from 'react-icons/fa';
import CustomButton from '../../../components/buttons';
import logo from "../../../assets/logo.png";
import { Link, useNavigate } from 'react-router-dom';

const Footer = ({ page = "default" }) => {

  let navigate = useNavigate();

  let details = [
    // { head: "Address", description: "27 Division St, New York, NY 10002, USA" },
    { head: "Website", description: "kanztainer.com" },
    { head: "Email", description: <Link to={"mailto:admin@kanztainer.com"}>admin@kanztainer.com</Link> },
    { head: "Phone (UAE)", description: <Link to={"tel:+971504521775"}>+971 50 4521775</Link> },
    { head: "Phone (PAK)", description: <Link to={"tel:+923222431418"}>+92 322 2431418</Link> },
  ];

  let services = [
    "Global Container Sourcing", "Container Inspection and Certification", 'Domestic Container Sales', 'Container Storage Services', "Logistics and Delivery Coordination"
  ];

  let types = ["Standard Containers", "Specialty Containers", "Refrigerated Containers", "Special Use Containers", "Dry Containers"];

  let links = [
    { link: "/about-us", name: "About Us" },
    { link: "/contact-us", name: "Help Link" },
    { link: "/services", name: "Services" },
    { link: "/about-us", name: "Why Choose Us" },
    { link: "/contact-us", name: "Contact Us" },
  ];

  return (
    <footer className="flex flex-col">
      {/* Contact Us Section */}
      {window.location.pathname !== "/contact-us" ? (
        <div className='flex flex-col gap-5 py-10 md:py-20 bg-white map_bg bg-contain bg-no-repeat bg-center'>
          <div className='flex items-center justify-center'>
            <span className='text-center font-semibold text-xl md:text-2xl lg:text-2xl w-[90%] md:w-[60%] lg:w-[35%]'>
              If You Have Any Questions about <br /> trading, Please Feel Free to Contact Us
            </span>
          </div>
          <div className='flex items-center justify-center'>
            <span className='text-center text-sm md:text-[14px] w-[90%] md:w-[60%] lg:w-[30%] text-gray-600'>
              Kanztainer is the world’s leading global containers supplier — we support industry and trade in global exchange.
            </span>
          </div>
          <div className='flex items-center justify-center gap-5 pb-10'>
            <CustomButton text='Request A Quote' type='yellowFilled' onClickCustom={() => navigate("/contact-us")} />
            <span className='flex items-center gap-2 group'>
              <FaPhoneAlt className='text-lg md:text-xl text-custom-yellow' />
              <Link to="tel:+971504521775" className='group-hover:text-custom-yellow text-sm transition-all'>+971 50 4521775</Link>
            </span>
          </div>
        </div>
      ) : null}

      {/* Footer Details Section */}
      <div className="bg-[#F5F5F5] py-10 md:py-[80px] flex justify-center">
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 md:gap-5 lg:ml-32 justify-center w-[90%] md:w-[80%]'>
          {/* Logo and Details */}
          <div className='flex flex-col justify-between gap-5'>
            <img src={logo} alt="Kanztainer Logo" className="w-[80px] md:w-[120px]" />
            {details?.map((x, i) => (
              <div key={i} className='flex items-start gap-1'>
                <span className='text-[12px] font-semibold'>{x.head}:</span>
                <span className='text-[12px]'>{x.description}</span>
              </div>
            ))}
          </div>

          {/* Services Section */}
          <div className='flex flex-col justify-between gap-2'>
            <span className='text-[16px] md:text-[17px] text-custom-blue font-medium h-[53px] pt-2 md:pt-5'>
              Our Services
            </span>
            {services?.map((x, i) => (
              <span key={i} onClick={() => navigate("/services")} className='text-[12px] hover:text-custom-blue cursor-pointer transition-all hover:-translate-y-[2px]'>
                {x}
              </span>
            ))}
          </div>

          {/* Container Types Section */}
          <div className='flex flex-col justify-between gap-2'>
            <span className='text-[16px] md:text-[17px] text-custom-blue font-medium h-[53px] pt-2 md:pt-5'>
              Containers Sectors
            </span>
            {types?.map((x, i) => (
              <span key={i} onClick={() => navigate("/about-us")} className='text-[12px] hover:text-custom-blue cursor-pointer transition-all hover:-translate-y-[2px]'>
                {x}
              </span>
            ))}
          </div>

          {/* Quick Links Section */}
          <div className='flex flex-col gap-3'>
            <span className='text-[16px] md:text-[17px] text-custom-blue font-medium h-[53px] pt-2 md:pt-5'>
              Quick Links
            </span>
            {links?.map((x, i) => (
              <span
                key={i}
                onClick={() => navigate(x.link)}
                className='text-[12px] hover:text-custom-blue cursor-pointer transition-all hover:-translate-y-[2px]'
              >
                {x.name}
              </span>
            ))}
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className='flex justify-center bg-[#F5F5F5]'>
        <div className='w-[90%] md:w-[80%] border-t border-gray-300 py-3 text-center text-[12px]'>
          Copyright © 2024 Kanztainer. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
