import React, { useEffect } from 'react'
import SliderSection from './components/home/slider'
import WhatWeDo from './components/home/whatWeDo'
import TypesOfContainers from './components/home/typesOfContainers'
import Quality from './components/home/quality'
import Testimonials from './components/home/testimonials'

const Home = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='flex flex-col '>
            <div className='flex flex-col gap-5'>
                <SliderSection />
                <WhatWeDo page="default" />
                <Quality />
                <TypesOfContainers />
            </div>
            <Testimonials />
        </div>
    )
}

export default Home