import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useRef, useState } from 'react'
import { SiComma } from 'react-icons/si';
import Slider from 'react-slick';
import videoBG from "../../../assets/video3.mp4";

const Testimonials = () => {

    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setIsVideoLoaded(true);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 10000,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
        nextArrow: <></>,
        prevArrow: <></>,
    };

    const items = [
        {
            head: "Kanztainer International Trading has been an invaluable partner in keeping our supply chain efficient. Their commitment to providing high-quality empty containers, coupled with their exceptional customer service, has allowed us to meet tight deadlines without a hitch. Whether we need standard 20ft or high cube containers, Kanztainer delivers every time. Their expertise in international trading is second to none!",
            name: "John W.",
            designation: "Logistics Manager, Global Freight Solutions"
        },
        {
            head: "For the past 5 years, Kanztainer has been our go-to provider for empty containers. Their global reach and understanding of regional markets ensure that we always get the right type of container for our cargo, no matter the destination. Their transparent trading practices and reliability have helped streamline our shipping operations, reducing delays and cutting costs. I can't recommend them enough!",
            name: "Emily R.",
            designation: "Operations Director, Pacific Exports"
        },
        {
            head: "The team at Kanztainer truly understands the importance of timeliness and quality in the container trading business. We’ve been working with them for several years, and their ability to source and deliver containers, even at short notice, has been critical to our operations. Their wide range of container options, from open-top to refrigerated units, means we can rely on them for any of our clients' needs. They're a trusted partner in every sense!",
            name: "David S.",
            designation: "Meridian Supply Chain Services"
        },
        {
            head: "Working with Kanztainer International Trading has been a seamless experience from the start. Their comprehensive services and dedication to quality have made them a preferred supplier in our container procurement process. Whether it's for dry containers or specialized tanks, Kanztainer consistently provides competitive rates and ensures timely delivery, helping us stay ahead in a highly competitive market.",
            name: "Sarah L.",
            designation: "Head of Procurement, Oceanic Transport Services"
        }
    ];

    const ItemsContainer = ({ head, name, designation }) => {
        return (
            <div className='flex flex-col gap-5 p-5 sm:p-10 lg:p-20'>
                <div className='flex items-center'>
                    <SiComma className='text-[15px] sm:text-[20px] text-custom-yellow' />
                    <SiComma className='text-[15px] sm:text-[20px] text-custom-yellow ml-[-5px] sm:ml-[-7px]' />
                </div>
                <div className='text-[11px] sm:text-sm leading-5 sm:leading-6 text-[#B0B3BC]'>{head}</div>
                <div className='flex flex-col'>
                    <div className='text-[12px] sm:text-[14px] font-semibold text-white'>{name}</div>
                    <div className='text-[10px] sm:text-[11px] text-[#B0B3BC]'>{designation}</div>
                </div>
            </div>
        );
    };

    return (
        <div className='grid grid-cols-1 md:grid-cols-2 h-[90vh]'>
            {/* Left section with slider */}
            <div className='bg-custom-blue flex justify-center items-center'>
                <div className='w-full relative'>
                    <Slider ref={sliderRef} {...settings}>
                        {items.map((x, i) => (
                            <ItemsContainer key={i} head={x.head} name={x.name} designation={x.designation} />
                        ))}
                    </Slider>
                    {/* Pagination Dots */}
                    <div className="absolute bottom-0 left-0 text-white px-5 sm:px-10 lg:px-20">
                        {items.map((_, index) => (
                            <span
                                key={index}
                                onClick={() => sliderRef.current.slickGoTo(index)}
                                className={`mx-1 sm:mx-2 mt-2 font-bold text-[20px] sm:text-[30px] ${currentSlide === index ? 'text-custom-yellow' : 'text-white'} cursor-pointer`}
                            >
                                .
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            {/* Right section (additional content or design) */}
            <div className="relative overflow-hidden">
                {/* Video Element */}
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="absolute inset-0 w-full h-full object-cover"
                    onLoadedData={handleVideoLoad}
                >
                    <source src={videoBG} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                {/* Black Overlay: Covers the video */}
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                {/* Spinner: Only shown while video is loading */}
                {!isVideoLoaded && (
                    <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center">
                        <Spin spinning={true} indicator={<LoadingOutlined spin className="text-custom-yellow text-4xl" />} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Testimonials;
