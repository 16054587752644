import React, { useState, useEffect } from 'react';
import Loader from './components/loader';
import Home from './pages/home';
import Header from './pages/components/globals/header';
import Footer from './pages/components/globals/footer';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AboutUs from './pages/aboutus';
import ContactUs from './pages/contact';
import Services from './pages/services';

import alot_of_boxes from "./assets/alot_of_boxes.jpg"
import around from "./assets/around.jpg"
import aroundTheWorld from "./assets/aroundTheWorld.png"
import container from "./assets/container.mp4"
import containerImage from "./assets/container.png"
import container_hub from "./assets/container_hub.jpg"
import container_wall_one from "./assets/container_wall_one.jpg"
import dummy_one from "./assets/dummy_one.png"
import dummy_three from "./assets/dummy_three.png"
import dummy_two from "./assets/dummy_two.png"
import gl_one from "./assets/gl_one.jpg"
import gl_two from "./assets/gl_two.jpg"
import logo from "./assets/logo.jpg"
import logoPNG from "./assets/logo.png"
import main_one from "./assets/main_one.jpg"
import main_ship_one from "./assets/main_ship_one.jpg"
import main_three from "./assets/main_three.jpg"
import main_two from "./assets/main_two.jpg"
import map from "./assets/map.png"
import slider_main_one from "./assets/slider_main_one.jpg"
import slider_one from "./assets/slider_one.jpg"
import slider_ship_container from "./assets/slider_ship_container.jpg"
import slider_two_people from "./assets/slider_two_people.jpg"
import video2 from "./assets/video2.mp4"
import video3 from "./assets/video3.mp4"
import video4 from "./assets/video4.mp4"
import worlds_leading from "./assets/worlds_leading.jpg"
import services from "./assets/services-bg-1.jpg"


const App = () => {
  const [loading, setLoading] = useState(true);

  const mediaItems = [
    { src: alot_of_boxes, type: 'image' },
    { src: around, type: 'image' },
    { src: aroundTheWorld, type: 'image' },
    { src: containerImage, type: 'image' },
    { src: container_hub, type: 'image' },
    { src: container_wall_one, type: 'image' },
    { src: dummy_one, type: 'image' },
    { src: dummy_three, type: 'image' },
    { src: dummy_two, type: 'image' },
    { src: gl_one, type: 'image' },
    { src: gl_two, type: 'image' },
    { src: logo, type: 'image' },
    { src: logoPNG, type: 'image' },
    { src: main_one, type: 'image' },
    { src: main_ship_one, type: 'image' },
    { src: main_three, type: 'image' },
    { src: main_two, type: 'image' },
    { src: map, type: 'image' },
    { src: slider_main_one, type: 'image' },
    { src: slider_one, type: 'image' },
    { src: slider_ship_container, type: 'image' },
    { src: slider_two_people, type: 'image' },
    { src: worlds_leading, type: 'image' },
    { src: services, type: "image" },
    { src: video2, type: 'video' },
    { src: video3, type: 'video' },
    { src: video4, type: 'video' },
    { src: container, type: 'video' },
  ];

  useEffect(() => {
    const imagePromises = mediaItems.map((item) => {
      return new Promise((resolve) => {
        const mediaElement = item.type === 'image' ? new Image() : document.createElement('video');
        mediaElement.src = item.src;

        mediaElement.onload = () => resolve(); // Resolve on image load
        mediaElement.onloadeddata = () => resolve(); // Resolve on video data loaded
        mediaElement.onerror = () => resolve(); // Resolve on error (to avoid hanging)
      });
    });

    Promise.all(imagePromises).then(() => {
      setLoading(false); // All images/videos are loaded
    });
  }, []);

  return (
    <Router>
      {loading
        ? <Loader />
        : <Routes>
          <Route
            path="/"
            element={
              <div className='relative'>
                <Header />
                <Home />
                <Footer />
              </div>
            } />
          <Route
            path="/about-us"
            element={
              <div className='relative'>
                <Header />
                <AboutUs />
                <Footer />
              </div>
            } />
          <Route
            path="/contact-us"
            element={
              <div className='relative'>
                <Header />
                <ContactUs />
                <Footer />
              </div>
            } />
          <Route
            path="/services"
            element={
              <div className='relative'>
                <Header />
                <Services />
                <Footer />
              </div>
            } />
        </Routes>
      }
    </Router>
  );
};

export default App;