import React, { useState } from "react";
import CustomButton from "../../../components/buttons";
import videoBG from "../../../assets/video4.mp4";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const ProvisionedServices = () => {

    const navigate = useNavigate()
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setIsVideoLoaded(true);
    };

    return (
        <div className="flex flex-col gap-10 mt-10 px-4 md:px-10 lg:px-20">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">

                {/* Video Section */}
                <div className="col-span-1 md:col-span-6 relative rounded-lg overflow-hidden w-full md:w-[80%] ">
                    {isVideoLoaded && (
                        <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center">
                            <Spin spinning={true} indicator={<LoadingOutlined spin className="text-custom-yellow" />} />
                        </div>
                    )}
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="absolute inset-0 w-full h-full object-cover"
                        onLoadedData={handleVideoLoad}
                    >
                        <source src={videoBG} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                {/* Text Section */}
                <div className="col-span-1 md:col-span-6 flex flex-col gap-5 py-10 md:py-20">
                    <div className='flex flex-col gap-1'>
                        <span className='text-[10px] sm:text-[12px] font-bold'>SERVICE FOR YOU</span>
                        <span className="text-[20px] sm:text-[24px] md:text-[34px] font-bold">High Level Of <span className="text-custom-yellow">Provisioned</span> Services</span>
                    </div>
                    <span className="flex flex-col gap-5">
                        <span className="text-[12px] md:text-sm">
                            With over 25 years in the container trading industry, we’ve established ourselves as a leading provider of high-quality, reliable containers.
                        </span>
                        <span className="text-[12px] md:text-sm">
                            We appreciate your trust greatly! Our clients choose us and our services because they know we’re the best.
                        </span>
                        <span className="text-[12px] md:text-sm">
                            Our value-added services ensure the smooth movement of containers, keeping supply chains efficient, streamlined, and ready for continuous operation.
                        </span>
                    </span>
                    <div className="flex items-center justify-start mt-2">
                        <CustomButton text="More About Us" type="blue" onClickCustom={() => navigate("/about-us")} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProvisionedServices;
